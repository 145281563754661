import {autoinject, bindable} from "aurelia-framework";

const moment = require("moment");
import * as Fhir from "../../classes/FhirModules/Fhir";
import {I18N} from "aurelia-i18n";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {ConfigService} from "../../services/ConfigService";

@autoinject
export class qTarget {
    @bindable item: any;
    @bindable response;
    @bindable previousresponse;
    @bindable readonly;
    @bindable options;
    @bindable view;
    @bindable changed: Function;

    i18n: I18N;
    responseItem: any;
    targetDateLinkId: string = undefined;
    targetValueLinkId: string = undefined;

    constructor(i18n: I18N) {
        this.i18n = i18n;
        this.pickerOptions.format = this.i18n.tr("date_format");
    }

    pickerOptions = {
        format: RuntimeInfo.DateFormat
    };

    get targetDays() : number[] {
        return ConfigService.CareTargetValues;
    }

    addDays(amount: number) {
        if (this.readonly) return;
        let m = moment(new Date()).add(amount, "days");
        this.targetDate = m.toDate();
        // this.set_targetDate(m.toJSON());
    }

    clearAll() {
        if (!this.item || !this.response || this.readonly) return;

        // this.targetDate = undefined;
        this.targetValue = undefined;

        this.responseItem.extension = this.responseItem.extension.filter(o => !o.url.endsWith(this.targetDateLinkId) && !o.url.endsWith(this.targetValueLinkId));
        if (this.responseItem.extension.length === 0) {
            delete this.responseItem.extension;
        }

        this.options.forEach(opt => {
            opt.targetValue = false;
        });

        this.view = false;
    }

    getResponseItem() {
        if (!this.response || !this.item) return;
        this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, true);
        if (this.responseItem) {
            this.targetDateLinkId = `target-date`;
            this.targetValueLinkId = `target-value`;

            const targetDateExtension = Fhir.Tools.GetOrCreateExtension(this.responseItem, this.targetDateLinkId, true);
            Fhir.Tools.GetOrCreateExtension(this.responseItem, this.targetValueLinkId, true);

            if (targetDateExtension?.valueDate) {
                targetDateExtension.valueDateTime = moment(targetDateExtension.valueDate).toJSON();
                delete targetDateExtension.valueDate;
                console.debug("Fixed assignment of valueDate to valueDateTime");
            }
        }
    }

    itemChanged() {
        this.targetDateLinkId = this.targetValueLinkId = undefined;
        this.getResponseItem();
    }

    responseChanged() {
        this.getResponseItem();
    }

    get targetValue(): string {
        if (!this.responseItem) return undefined;
        return Fhir.Tools.GetExtensionValue(Fhir.Tools.GetOrCreateExtension(this.responseItem, this.targetValueLinkId, false));
    }

    set targetValue(newValue: string) {
        if (!this.responseItem || this.readonly) return;

        let ext = Fhir.Tools.GetOrCreateExtension(this.responseItem, this.targetValueLinkId, true);
        ext.valueString = newValue;

        let option = this.options.find(o => o.value === newValue);
        if (option) {
            option.targetValue = true;
        }

        if (typeof this.changed === "function") this.changed(this);
    }

    setTargetValue(value: string) {
        this.targetValue = value;
    }

    set targetDate(newVal: Date) {
        if (!this.responseItem || this.readonly) return;

        try {
            if (this.targetDate && newVal && newVal.valueOf() === this.targetDate.valueOf()) return;
        } catch (e) {
        }


        let ext = Fhir.Tools.GetOrCreateExtension(this.responseItem, this.targetDateLinkId, true);
        let n = newVal ? moment(newVal, RuntimeInfo.DateFormat).toJSON() : undefined;
        ext.valueDateTime = n;

        if (typeof this.changed === "function")
            this.changed(this);
    }

    get targetDate(): Date {
        let result = new Date();
        if (!this.responseItem) return result;
        let ext = Fhir.Tools.GetOrCreateExtension(this.responseItem, this.targetDateLinkId, false);
        if (!ext) return result;
        let curVal = Fhir.Tools.GetExtensionValue(ext);
        if (typeof curVal === "undefined" || curVal === "") return result;

        return new Date(curVal);
    }
}
