import {autoinject, bindable} from "aurelia-framework";
import {PatientService} from "../../services/PatientService";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {fhirEnums} from "../../classes/fhir-enums";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {I18N} from "aurelia-i18n";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {QuestionnaireResponse, Questionnaire} from "../../classes/FhirModules/Fhir";
import {IAnswer} from "../../classes/IAnswer";

const moment = require("moment");

@autoinject
export class QResponseList {
    @bindable item: any;
    @bindable responseName: string;
    @bindable encounter;
    responses: any[] = [];
    i18n: I18N;
    parentDiv: HTMLDivElement;

    constructor(i18n: I18N) {
        this.i18n = i18n;
    }

    async navigateToItem(response: any) {
        if (!response || !response.id) return;

        let id = response.id;
        let qList = await QuestionnaireService.GetQuestionnaireIds();
        let kvp = [];
        let name = this.responseName.toUpperCase();
        if (name.indexOf('CAREIT') === 0) name = name.substr(6);
        if (name.indexOf('CARE_IT') === 0) name = name.substr(7);
        if (name.indexOf('_') === 0) name = name.substr(1);
        let route: string = undefined;

        switch (name) {
            case "ASSESSMENT":
                route = "assessment";
                break;
            case "FALL":
                route = "fall";
                break;
            case "DISCHARGEMANAGEMENT":
                route = "discharge";
                break;
            case "ANAMNESIS":
                route = "anamnesis";
                break;
            case "WOUND":
                route = "wounds";
                break;
            case "VERLEGUNGSBERICHT":
                route = "transfer";
                break;
            case "ADMIN_ISO":
                route = "isolation";
                break;
        }

        /*
        "PsychAnamnesis",
        "PsychAssessment",
        "PsychDiagnosis",
        "Bvc",
        "Ngasr",
        "PsychNotfallAssessment",
        "Anamnesis",
        "Wound",
        "Verlegung",
        "Isolation" */
        Object.keys(qList).forEach(key => {
            kvp.push({key: key.toUpperCase(), value: qList[key]});
        });

        let hash = `/encounter/${this.encounter}/${route}/${id}`;

        window.location.hash = hash;
    }

    fieldIdsToDisplay: any[];


    itemChanged() {
        if (!this.item || !this.item.extension) return;
        let ext = this.item.extension.find(o => o.url.endsWith('response-list'));
        if (!ext) return;
        let name: string;
        if (ext.valueString.indexOf(',') > 0) {
            const sa = ext.valueString.split(',');
            name = String(sa[0]).trim();

            sa.splice(0, 1);
            this.fieldIdsToDisplay = sa.slice();
            for (let i = 0; i < this.fieldIdsToDisplay.length; i++)
                this.fieldIdsToDisplay[i] = String(this.fieldIdsToDisplay[i]).trim();
            this.alternateDisplay = this.fieldIdsToDisplay.length > 0;
        } else {
            name = ext.valueString;
        }

        this.responseName = name;

        if (this.parentDiv) {
            $(this.parentDiv).data("response-list", this);
        }
    }

    displayHeaders: any[] = [];
    displayData: any[] = [];
    alternateDisplay: boolean = false;

    responseNameChanged() {
        if (!this.responseName) return;

        let q = QuestionnaireService.GetQuestionnaireByNameDirect(this.responseName);
        if (q) {
            if (this.fieldIdsToDisplay && this.fieldIdsToDisplay.length > 0) {
                this.displayHeaders = [];
                for (const fieldId of this.fieldIdsToDisplay) {
                    const displayItem = Questionnaire.GetQuestionnaireItemByLinkId(q, fieldId);
                    if (displayItem) this.displayHeaders.push(displayItem);
                }

                this.displayData = [];
            }

            let rs = QuestionnaireService.GetResponsesOfType(PatientService.LatestPatient, q.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]);
            rs.forEach(r => {
                this.responses.push({
                    id: r.id,
                    authored: moment(r.authored).format(RuntimeInfo.DateTimeFormat),
                    status: this.i18n.tr(r.status),
                    author: r.author ? r.author.display : '-'
                });

                if (this.displayData && this.fieldIdsToDisplay && this.fieldIdsToDisplay.length > 0) {
                    const row = [];
                    for (const itemId of this.displayHeaders.map(o => o.linkId)) {
                        // const responseValue = QuestionnaireResponse.GetResponseItemValueByLinkId(r,id, undefined);
                        const responseItem = QuestionnaireResponse.GetResponseItemByLinkId(r, itemId, undefined);
                        if (responseItem) {
                            const item: any = this.displayHeaders.find(o => o.linkId === itemId);
                            if (item) {
                                if (item.type === 'string' || item.type === 'integer' || item.type === 'decimal' || item.type === 'text' || item.type === 'time')
                                    row.push(String(QuestionnaireResponse.GetResponseItemValue(responseItem, '')));
                                else if (item.type === 'date') {
                                    const d = moment(QuestionnaireResponse.GetResponseItemValue(responseItem, ''));
                                    row.push(d.format(RuntimeInfo.DateFormat));
                                } else if (item.type === 'dateTime') {
                                    const d = moment(QuestionnaireResponse.GetResponseItemValue(responseItem, ''));
                                    row.push(d.format(RuntimeInfo.DateTimeFormat));
                                } else if (item.type === 'open-choice' || item.type === 'choice') {
                                    row.push(QuestionnaireResponse.GetResponseItemDisplay(responseItem));
                                } else row.push('Type?:' + itemId);
                            } else {
                                row.push('Q:' + itemId + ' not found');
                            }
                        } else {
                            row.push('R:' + itemId + ' not found');
                        }
                    }

                    if (row.length === this.displayHeaders.length) {
                        this.displayData.push({responseId: r.id, cells: row, response: r});
                    }
                }
            });
        }
    }
}
