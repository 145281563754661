import { autoinject } from "aurelia-dependency-injection";
import { I18N } from "aurelia-i18n";
import { bindable } from "aurelia-templating";
import { QuestionnaireResponse } from "resources/classes/FhirModules/QuestionnaireResponse";
import { NitTools } from "resources/classes/NursitTools";
import SystemHeaders from "resources/classes/SystemHeaders";
import { ConfigService } from "resources/services/ConfigService";

@autoinject
export class QVabutton {
    //#region Variables
    _currentValue: string;
    _subValue: string;
    @bindable view: boolean;
    @bindable response: any;
    @bindable item: any;
    @bindable changed: Function;

    public static urlPart: string = 'va';
    public static urlPartMain: string = 'va-amount';
    public static urlPartSub: string = 'va-responsibility';
    selectedButton; // the currently selected main button
    responseItem: any;

    // this are the buttons to display in the first row
    buttons = [
        {
            text: "va_button_low",
            value: "low",
            selected: false,
            showSubItems: false
        },
        {
            text: "va_button_mostly",
            value: "mostly",
            selected: false,
            showSubItems: false
        },
        {
            text: "va_button_complete",
            value: "complete",
            selected: false,
            showSubItems: false
        }
    ];

    // these items will be displayed, when the currently selected button (see "buttons" property) has showSubItems = true
    subItems = [
        {
            text: " ",
            value: "unspecified",
            selected: false
        },
        {
            text: "Patient",
            value: "patient",
            selected: false
        },
        {
            text: "Pflege/Versorger",
            value: "nursing",
            selected: false
        }
    ];

    get baseUrl(): string {
        return `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/fhir/structureDefinitions`;
    }

    get subValue(): string {
        return this._subValue;
    }

    set subValue(value: string) {
        this._subValue = value;
        for (const item of this.subItems) {
            item.selected = item.value === value;
        }
    }

    get currentValue(): string {
        return this._currentValue;
    }

    set currentValue(value: string) {
        this._currentValue = value;
        for (const btn of this.buttons) {
            btn.selected = btn.value == value;
            if (btn.selected)
                this.selectedButton = btn;
        }

        this.notify();
    }

    get isValid(): boolean {
        if (!this.item || !this.response || !this.item.linkId) return false;
        if (!this.responseItem) {
            this.responseItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId);
        }

        return !!this.responseItem;
    }
    //#endregion

    constructor(protected i18n: I18N) {
        for (const btn of this.buttons) {
            btn.text = i18n.tr(btn.text);
        }
    }
    /**
     * Removes the extension from the current Codings
     * @param urlPart the system url to remove
     */
    removeExtension(urlPart: string) {
        if (!this.isValid || !this.responseItem.extension) return;

        const codings = this.getMainCodings();
        const existing = codings.find(o => o.system.endsWith(`/${urlPart}`));
        if (existing) {
            codings.splice(codings.indexOf(existing), 1);
        }

        if (codings.length == 0) {
            // when there is no coding left then remove the extension from the responseItem
            const extension = this.getMainExtension();
            this.responseItem.extension.splice(this.responseItem.extension.indexOf(extension), 1);
        }

        if (this.responseItem.extension.length == 0) {
            delete this.responseItem.extension;
        }
    }

    /**
     * Removes all extensions and values from the response and closes the display
     */
    clearAll() {
        this.view = false;
        this.clearSubItem(false);
        this.clearItem(false);
        this.notify();
    }

    /**
     * Clears and Removes the main level item from the extension
     * @param notify Indicates whether the parent component should be notified
     */
    clearItem(notify: boolean = true) {
        if (!this.isValid || !this.responseItem.extension) return;
        this.removeExtension(QVabutton.urlPartMain);
        this.currentValue = undefined;

        if (notify)
            this.notify();
    }

    /**
     * Clears and Removes the sub level item from the extension
     * @param notify Indicates whether the parent component should be notified
     * @returns 
     */
    clearSubItem(notify: boolean = true) {
        if (!this.isValid || !this.responseItem.extension) return;
        this.removeExtension(QVabutton.urlPartSub);
        this.subValue = undefined;
        if (notify)
            this.notify();
    }

    private notify(): void {
        if (typeof this.changed === "function")
            this.changed(this);
    }

    _showVAHeader: boolean = undefined;
    get showVAHeader(): boolean {
        if (typeof this._showVAHeader === "undefined" && ConfigService.cfg && ConfigService.cfg.features && typeof ConfigService.cfg.features.showVAHeader === "boolean") {
            this._showVAHeader = ConfigService.cfg.features.showVAHeader;
        }

        if (typeof this._showVAHeader === "boolean")
            return this._showVAHeader;
        else
            return true;
    }

    attached() {
        if (this.isValid) {
            this.currentValue = this.getExtensionValue(QVabutton.urlPartMain);
            this.subValue = this.getExtensionValue(QVabutton.urlPartSub);
        }
    }

    /**
     * Gets or Creates the extension to store the current values in
     * @returns the extension the values will be stored to
     */
    getMainExtension(): any {
        if (!this.responseItem.extension) this.responseItem.extension = [];

        let existing = this.responseItem.extension.find(o => o.url.endsWith(`/${QVabutton.urlPart}`));
        if (!existing) {
            existing = {
                url: `${this.baseUrl}/${QVabutton.urlPart}`
            };

            this.responseItem.extension.push(existing);
        }

        if (!existing.valueCodeableConcept) {
            existing.valueCodeableConcept = {
                coding: [],
                text: 'Zuständigkeit ' + this.item.text
            };
        }

        return existing;
    }

    /**
     * Gets or Creates the Coding-Array in which the current values will be stored
     * @returns the Coding-Array in which the current values will be stored
     */
    getMainCodings(): any[] {
        if (!this.isValid) return;

        const existing = this.getMainExtension();
        const concept = existing.valueCodeableConcept;
        if (!concept.coding) concept.coding = [];

        return concept.coding;
    }

    /**
     * Gets the current value of the extension coding
     * @param url the system to get the value from
     * @returns the current value or undefined
     */
    getExtensionValue(url: string): string {
        let result: string = undefined;
        let codings = this.getMainCodings();

        let coding = codings.find(o => o.system.endsWith(`/${url}`));
        if (coding)
            result = coding.code;

        return result;
    }

    /**
     * Sets the current extension value
     * @param url the system for which the value should be set
     * @param code the value to set
     * @param text the display of the extension value
     */
    setExtensionValue(url: string, code: string, text: string) {
        if (!this.isValid) return;

        let codings = this.getMainCodings();

        let coding = codings.find(o => o.system.endsWith(`/${url}`));
        if (!coding) {
            coding = {
                system: `${this.baseUrl}/${url}`,
                code: code,
                display: text
            };

            codings.push(coding);
        }

        coding.code = code;
        coding.display = text;
    }

    vaButtonClicked(sender) {
        if (!this.isValid || !sender) return;

        const extensionValue = sender.value;
        if (this.currentValue === extensionValue) return;
        this.setExtensionValue(QVabutton.urlPartMain, extensionValue, sender.text);
        this.currentValue = extensionValue;

        if (!sender.showSubItems) {
            this.clearSubItem(true);
        }
        else {
            if (!this.subValue) {
                this.subValueButtonClicked(this.subItems[0]);
            }
        }
    }

    subValueButtonClicked(sender) {
        const extensionValue = sender.value;
        if (!this.isValid || this.subValue === extensionValue) return;
        this.setExtensionValue(QVabutton.urlPartSub, extensionValue, sender.text);
        this.subValue = extensionValue;
    }
}
