import {autoinject, bindable} from "aurelia-framework";
import {NitTools} from "../../classes/NursitTools";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {FhirService} from "../../services/FhirService";
import {I18N} from "aurelia-i18n";
import {parseInt} from "lodash";
import {PatientItem} from "../../classes/Patient/PatientItem";

@autoinject
export class qObservation {
/* // 8302-2 - body height
    // 29463-7 - Body Weight */
    @bindable item;
    @bindable response;
    @bindable headerchanged : Function;
    @bindable hidePlaceholder : boolean;
    @bindable changed : Function;
    @bindable previousResponse;
    @bindable readonly : boolean;
    @bindable encounterId : string;

    responseItem;
    currentText;
    inputType : 'number' | 'text' | 'label' = "text";
    observationCode : string;
    inputStep : string = '';
    defaultUnit : string = '';
    defaultSystem : string = "http://loinc.org";

    async loadLatestObservation() {
        let url = `Observation?code=${this.defaultSystem}|${this.observationCode}&_count=1&_sort=-date`;
        this.encounterId = PatientItem.LastLoadedPatient.encounterId;
        if (FhirService.FhirVersion >= 4)
            url += "&patient=" + PatientItem.LastLoadedPatient.id;
        else
            url += "&subject=" + PatientItem.LastLoadedPatient.id;

        const obsBundle = await Fhir.Rest.Get(url);
        if (NitTools.IsArray(obsBundle.entry)) {
            const observation : fhir4.Observation = obsBundle.entry.find(o=>o.resource)?.resource;

            if (observation?.valueQuantity) {
                /* "value": 180,
               "unit": "cm",
               "code": "cm" */
                if (this.inputType === "number") {
                    if (this.inputStep === "0") {
                        this.currentText = parseInt(`${observation.valueQuantity.value}`);
                    } else {
                        this.currentText = parseFloat(`${observation.valueQuantity.value}`);
                    }
                }
                else {
                    this.currentText = `${observation.valueQuantity.value}${observation.valueQuantity.unit || observation.valueQuantity.code}`;
                }
            } else if (observation.valueString) {
                this.currentText = observation.valueString;
            } else if (observation.valueInteger) {
                this.currentText = observation.valueInteger;
            } else if (observation.valueBoolean) {
                this.currentText = this.i18n.tr(observation.valueBoolean ? 'yes' : 'no');
            }
        }
    }

    itemChanged(newVal, oldVal) {
        if (newVal) {
            // type: ('display'|'boolean'|'decimal'|'integer'|'string'|'text'|'url'|'quantity');
            if (newVal.type === "decimal" || newVal.type === "integer") {
                this.inputType = "number";
                if (newVal.type === "decimal")
                    this.inputStep = '0.1';
                else
                    this.inputStep = '0';

            } else if (newVal.type === "display") {
                this.inputType = "label";
            } else {
                this.inputType = "text";
            }

            if (NitTools.IsArray(newVal.extension)) {
                const extension = newVal.extension?.find(o => o.url?.endsWith('/questionnaire-observation-value'));
                if (extension.valueQuantity) {
                    this.observationCode = extension.valueQuantity.code;
                    this.defaultSystem = extension.valueQuantity.system;
                    this.defaultUnit = extension.valueQuantity.unit;
                } else if (extension.valueCoding) {
                    this.observationCode = extension.valueCoding.code;
                    this.defaultSystem = extension.valueCoding.system;
                    this.defaultUnit = extension.valueCoding.display||this.defaultUnit;

                }
                else {
                    this.observationCode = extension?.valueCode || extension?.valueString;
                }
            }
        }

        this.updateValueFromResponse();
    }

    responseChanged(newVal, oldVal) {
        this.updateValueFromResponse();
    }

    async updateValueFromResponse() {
        if (!this.item?.linkId || !this.response) return;

        this.responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId, false);
        if (this.response.status === 'in-progress') {
            await this.loadLatestObservation();
            this.inputBlurred();
        } else {
            if (NitTools.IsArray(this.responseItem?.answer)) {
                this.currentText = String(Fhir.QuestionnaireResponse.GetResponseItemValue(this.responseItem));
            }
        }
    }

    inputBlurred() {
        if (!this.responseItem) return;

        this.responseItem.answer = [{
            valueString: this.currentText
        }];

        if (typeof this.changed === "function")
            this.changed(this.responseItem);
        console.warn(`Updated Text to: ${this.currentText}`);
    }

    constructor(protected fhirService : FhirService, protected i18n: I18N) {

    }
}
