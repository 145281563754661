import {PatientItem} from "../../classes/Patient/PatientItem";
import {ImageEncounter} from "./images-history";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {ConfigService} from "../../services/ConfigService";
import {IThumb} from "../../../views/patient/wunden/wound-main";
import {DialogController} from "aurelia-dialog";
import {FhirService} from "../../services/FhirService";
import {I18N} from "aurelia-i18n";
import {WoundDataHandler} from "../../../views/patient/wunden/wound-data-handler";
import {bindable} from "aurelia-templating";
import {autoinject} from "aurelia-framework";
const moment = require("moment");

@autoinject
export class CompleteImageHistory {
    @bindable patient: PatientItem;
    @bindable button;
    encounterList : ImageEncounter[] = undefined;
    loadingEncounters : boolean = false;
    @bindable selectedThumb: IThumb;
    dataHandler: WoundDataHandler;
    protected fhirService : FhirService;
    constructor(protected controller: DialogController, protected i18n : I18N)
    {
        this.fhirService = new FhirService(FhirService.Endpoint);
        this.dataHandler = new WoundDataHandler(i18n);
    }

    async loadAllImages()
    {
        if (!this.patient || this.loadingEncounters || this.encounterList && this.encounterList.length > 0)
            return;

        try {
            this.loadingEncounters = true;

            // load media
            // use  Media?_format=json&subject=Patient/95cc7024-3c93-4033-b5d5-bf7f713d42c5&type=photo&subtype=thumbnail&_summary=true
            // to get a list of Thumbnails, then
            // filter the medias because only the ones with the identifier /media-source-id are valid to be displayed
            // the encounter is linked in media.context. So we should load the encounter and cache it in a list to get at least the date of the encounter
            // the wound information (location of the wound) is in media.bodySite in a coding with system ending with body-part and should be translatet by the code property

            this.encounterList = [];

            // load all encounters for this patient:
            const encounterUrl = `Encounter?subject=Patient/${this.patient.id}`;
            const encounters = <ImageEncounter[]>await this.fhirService.fetch(encounterUrl);

            for (const encounter of encounters) {
                encounter.wounds = await this.dataHandler.loadWounds(encounter.id);

                for (const woundGroup of encounter.wounds) {
                    for (const woundGroupItem of woundGroup.children) {
                        woundGroupItem.thumbGroups = await this.dataHandler.loadThumbNails(encounter.id, woundGroupItem);
                    }
                }

                let encounterText = '';
                if (encounter.period) {
                    let start = encounter.period.start;
                    if (start) {
                        start = moment(start).format(RuntimeInfo.DateFormat);
                    } else {
                        start = '?';
                    }

                    let end = encounter.period.end;
                    if (end) {
                        end = moment(end).format(RuntimeInfo.DateFormat);
                    } else {
                        end = '?';
                    }

                    encounterText = `${start} - ${end}`;
                }

                if (encounter.status)
                    encounterText += ', <br />' + this.i18n.tr(encounter.status);

                encounter.text = {
                    div: encounterText,
                    status: 'generated'
                };

                this.encounterList.push(encounter);
            }

            if (this.encounterList && this.encounterList.length > 0) {                
                const encounter = this.encounterList.find(o => o.wounds && o.wounds.length > 0 && o.wounds);
                
                if (encounter) {
                    const wound = encounter.wounds.find(o => o.children && o.children.length > 0);
                    if (wound) {
                        const child = wound.children.find(o => o.thumbGroups && o.thumbGroups.length > 0); // WoundGroupItem
                        if (child) {
                            const thumbGroup = child.thumbGroups.find(o => o.items && o.items.length > 0);
                            if (thumbGroup) {
                                const thumbGroupItem = thumbGroup.items.find(o => o.resource);
                                if (thumbGroupItem)
                                    this.selectThumb(thumbGroupItem.resource);
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            console.warn(e);
        }
        finally {
            this.loadingEncounters = false;
        }
    }

    selectThumb(media : any) {
        const ident = media.identifier.find(o=>o.system.endsWith('/media-source-id'));
        let imgUrl: string = '';
        if (ident && ident.value) {
            imgUrl = `Media/${ident.value}`;
        }

        this.selectedThumb = {
            resource: media,
            imageUrl: imgUrl,
            thumb: undefined,
            title: undefined,
            print: false,
            thumbEdited: media.meta?.lastUpdated
        };
    }

    async attached() {
        await this.loadAllImages();
    }
}
