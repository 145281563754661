import {autoinject, bindable} from "aurelia-framework";
import {NitTools} from "resources/classes/NursitTools";
import {FhirService} from "resources/services/FhirService";
import PinchZoom from "pinch-zoom-js";
import {ImageList} from "./image-list";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import * as moment from "moment";

@autoinject
export class ImageView {
    created: string;
    @bindable item: any;
    @bindable imageSource: string;
    @bindable button;
    loading: boolean = false;
    showInfo: boolean = false;
    instanceUid: string;
    imageParent: HTMLDivElement;
    media: any;
    isMouseDown: boolean = false;
    curPos: { x, y } = {x: 0, y: 0};
    oldPos: { x, y } = {x: 0, y: 0};
    f: number = 1;
    image: HTMLImageElement;
    scrollZoom: ScrollZoom;
    pz: PinchZoom;

    constructor(protected fhirService: FhirService) {
        this.instanceUid = NitTools.UidName();
    }

    get title() {
        if (this.media && this.media.content && this.media.content.title)
            return this.media.content.title;

        return undefined;
    }

    imageLoaded() {
        this.detached();
        if (this.imageSource) {
            window.setTimeout(() => {
                if (this.imageParent) {
                    this.scrollZoom = new ScrollZoom(this.imageParent, 8, 0.2);
                    this.scrollZoom.enabled = !!this.button;
                    this.createPinch();
                }
            }, 250);
        }
    }

    createPinch() {
        if (!this.pz) this.pz = new PinchZoom(this.image, {maxZoom: 8, minZoom: 0.2});
        this.pz["zoomFactor"] = 1;
        this.pz["update"]();
        if (!this.button) this.pz.disable();
    }

    detached() {
        if (this.scrollZoom) {
            this.scrollZoom.pos.x = 0;
            this.scrollZoom.pos.y = 0;
            this.scrollZoom.scale = 1;
            this.scrollZoom.update();

            this.scrollZoom.dispose();
            this.scrollZoom = undefined;
        }

        // reset image
        if (this.image) {
            this.image.style.transform = undefined;
            this.image.style.translate = undefined;
            this.image.style.transformOrigin = "0 0";
        }
    }

    resetView() {
        this.imageLoaded();
    }

    async itemChanged(item) {
        try {
            this.loading = true;

            if (!item) {
                return;
            }

            try {
                if (item.resource && item.resource.content && item.resource.content.creation) {
                    const crt = moment(item.resource.content.creation).format(RuntimeInfo.DateTimeFormat);
                    this.created = crt.split(',').join('<br />');
                } else {
                    this.created = '';
                }
            } catch (err) {
                console.warn(err);
                this.created = '';
            }

            if (item.imageUrl) {
                const url = `${item.imageUrl}`;
                const id = item.imageUrl.split('/')[1];
                if (ImageList.imageCache[id]) {
                    this.imageSource = ImageList.imageCache[id];
                } else {                    
                    this.media = <any>await this.fhirService.get(url);
                    if (this.media) {
                        this.imageSource = `data:${this.media.content.contentType};base64,${this.media.content.data}`;
                        ImageList.imageCache[id] = this.imageSource;
                    }
                }
            }
        }
        catch (ex) {
            console.warn(ex);
        }
        finally {
            this.loading = false;
        }
    }
}

export class ScrollZoom {
    size: { w: number; h: number; };
    offset: any;
    zoom_point: { x: number; y: number; };
    zoom_target: { x: number; y: number; };
    scale: number;
    pos: { x: any; y: any; };
    lastPos: { x: any; y: any; };
    container: JQuery<any>;
    target: JQuery<any>;
    enabled: boolean = true;
    mouseDown: boolean = false;

    constructor(container, protected max_scale, protected factor) {
        this.container = $(container);
        this.target = $(this.container.children().first());
        this.size = {w: this.target.width(), h: this.target.height()};
        this.pos = {x: 0, y: 0};
        this.zoom_target = {x: 0, y: 0};
        this.zoom_point = {x: 0, y: 0};
        this.scale = 1;
        this.target.css('transform-origin', '0 0');
        this.target.on("mousewheel DOMMouseScroll", (e) => this.scrolled(e));
        this.target.on("mousedown", (e) => this.onMouseDown(e));
        this.target.on("mouseup mouseout", (e) => this.onMouseUp(e));
        this.target.on("mousemove", (e) => this.onMouseMove(e));

        this.reset();
    }

    scrolled(e) {
        // const _this = this; // $(e.currentTarget).data("zoomScroll");

        this.offset = this.container.offset();
        this.zoom_point.x = e.pageX - this.offset.left;
        this.zoom_point.y = e.pageY - this.offset.top;

        e.preventDefault();
        let delta = e.delta || e.originalEvent.wheelDelta;
        if (delta === undefined) {
            //we are on firefox
            delta = e.originalEvent.detail;
        }
        delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

        // determine the point on where the slide is zoomed in
        this.zoom_target.x = (this.zoom_point.x - this.pos.x) / this.scale;
        this.zoom_target.y = (this.zoom_point.y - this.pos.y) / this.scale;

        // apply zoom
        this.scale += delta * this.factor * this.scale;
        this.scale = Math.max(1, Math.min(this.max_scale, this.scale));

        // calculate x and y based on zoom
        this.pos.x = -this.zoom_target.x * this.scale + this.zoom_point.x;
        this.pos.y = -this.zoom_target.y * this.scale + this.zoom_point.y;

        // Make sure the slide stays in its container area when zooming out
        /* if (this.pos.x > 0)
            this.pos.x = 0
            if (this.pos.x + this.size.w * this.scale < this.size.w)
            this.pos.x = -this.size.w * (this.scale - 1)
        if (this.pos.y > 0)
            this.pos.y = 0
        if (this.pos.y + this.size.h * this.scale < this.size.h)
            this.pos.y = -this.size.h * (this.scale - 1) */

        this.update();
    }

    onMouseDown(e) {
        this.mouseDown = true;
        this.lastPos = {x: e.clientX, y: e.clientY};
    }

    onMouseUp(e) {
        this.mouseDown = false;
        e.preventDefault();
    }

    onMouseMove(e) {
        if (!this.mouseDown) return;
        e.preventDefault();

        const dX = e.clientX - this.lastPos.x;
        const dY = e.clientY - this.lastPos.y;
        // this.pos.y += tmpPost.y - this.lastPos.y;

        this.lastPos = {x: e.clientX, y: e.clientY};

        this.pos.x += dX;
        this.pos.y += dY;
        this.update();
    }

    reset() {
        this.target.css('transform-origin', '0 0');
        this.pos = {x: 0, y: 0};
        this.zoom_target = {x: 0, y: 0};
        this.zoom_point = {x: 0, y: 0};
        this.scale = 1;

        const cW = this.container.width();
        const iW = this.target.width();
        const cH = this.container.height();
        const iH = this.target.height();
        this.pos.x = (cW / 2) - (iW / 2);
        this.pos.y = (cH / 2) - (iH / 2);
        this.lastPos = NitTools.Clone(this.pos);

        this.update();
    }

    dispose() {
        this.target.off("mousewheel DOMMouseScroll mousedown mouseup mouseout mousemove");
    }

    update() {
        if (!this.enabled) return;
        const w = (this.size.w * this.scale);
        const h = (this.size.h * this.scale) + 36;
        const maxX = this.container.width() * .5;
        const minX = -w + maxX;
        const maxY = this.container.height() * .5;
        const minY = -h + maxY;

        if (this.pos.x < minX) this.pos.x = minX;
        else if (this.pos.x > maxX) this.pos.x = maxX;

        if (this.pos.y < minY) this.pos.y = minY;
        else if (this.pos.y > maxY) this.pos.y = maxY;

        this.target.css('transform', 'translate(' + (this.pos.x) + 'px,' + (this.pos.y) + 'px) scale(' + this.scale + ',' + this.scale + ')');
    }
}
